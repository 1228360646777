/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import ContentPageTemplate from "@src/templates/contentPageTemplate"

const OurImpact = () => {
  const data = useStaticQuery(
    graphql`
      query {
        pageData: markdownRemark(
          fileAbsolutePath: { glob: "**/pages/our-impact/index.md" }
        ) {
          frontmatter {
            header {
              ...Header
            }
            threeColumnTextBlock {
              heading
              columns {
                image
                heading
                blockHeading
                text
                ctaButton {
                  label
                  link
                }
              }
            }
            imageGallery{
              image1
              image2
              image3
              image4
              image5
              image6
            }
            threeUpBlock {
                heading
                columns {
                    text
                }
            }
            carouselStats {
              ...CarouselStats
            }
            imageTextBlock {
              gradientBackground
                rows {
                image
                heading
                text
                links {
                  label
                  link
                }
              }
            }
            smallCta {
                image
                imageFit
                heading
                text
                backgroundImg
                ctaButton {
                    label
                    link
                }
            }
            ctaBlock {
              heading
              subheading
              text
              ctaButton {
                label
                link
              }
            }
            carousel {
                heading
                image
                text
                footnote
                ctaButton {
                    label
                    link
                }
            }
            accordion {
                ...Accordion
            }
            ogTitle
            ogimage
            ogdescription
          }
        }
      }
    `
  )

  return <ContentPageTemplate data={data} />
}



export default OurImpact
